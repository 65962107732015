body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS for sweetalerts */
.swal-text {
  text-align: center;
  line-height: 1.5;
}

.swal-button--confirm {
  background-color: #008640;
}

.swal-button--cancel {
  background-color: #ef8a6f;
}

#main-content {
  z-index: 1;
  background: #fcfcfe;
}
